@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Pacifico&display=swap&family=Bree+Serif&display=swap');

@layer base {
  h1 {
    @apply text-heading whitespace-normal font-kanit font-black text-h1 leading-heading break-normal;
  }
  h2 {
    @apply text-heading whitespace-normal font-kanit font-bold text-5xl leading-heading uppercase pb-4 break-normal;
  }
  h3 {
    @apply text-heading whitespace-normal font-kanit font-bold text-3xl leading-heading uppercase pb-2 break-normal;
  }
  h4 {
    @apply text-heading whitespace-normal font-breeserif leading-heading text-base text-2xl pb-2 break-normal;
  }
  p {
    @apply break-normal whitespace-normal;
  }
  body {
    @apply bg-black;
  }
  html {
    @apply font-kanit text-base leading-base pb-1;
  }
}
:root {
  --primary-color: white;
}

.styled-link > a {
  @apply font-bold underline text-primary;
}

.section-container {
  @apply mx-auto px-5 sm:px-8 max-w-7xl pb-20;
}

.centering {
  @apply flex justify-center items-center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
