.noise-container {
  position: relative;
}

.noise-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/noisy-filter.png');
  background-repeat: repeat;
  pointer-events: none;
  z-index: 999;
}

@keyframes noise-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 10%);
  }
  50% {
    transform: translate(10%, 5%);
  }
  60% {
    transform: translate(5%, -5%);
  }
  70% {
    transform: translate(-10%, -10%);
  }
  80% {
    transform: translate(5%, 10%);
  }
  90% {
    transform: translate(-5%, -5%);
  }
  100% {
    transform: translate(0, 0);
  }
}
